import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  type IZoomClient,
  MockZoomClient,
  ZoomClient,
} from '../../../app/components/Zoom/client';
import { getZoomEnv } from '../../../app/components/Zoom/utils';
import {
  getFeatureQueryParam,
  getFeatureQueryParamArray,
  getFeatureQueryParamString,
} from '../../hooks/useFeatureQueryParam';
import { getLogger } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { uuidv4 } from '../../utils/common';
import { FeatureChecker } from '../Product/FeatureChecker';

export const log = getLogger().scoped('zoom');

type ZoomEnv = ReturnType<typeof getZoomEnv>;

export function useZoomEnv(
  mock = getFeatureQueryParam('zoom-mock-env')
): ZoomEnv {
  return useMemo(() => {
    if (!mock) return getZoomEnv();
    return {
      user: {
        account_id: '',
        account_number: 0,
        display_name: 'John Smith',
        email: '',
        first_name: 'John',
        id: uuidv4(),
        last_name: 'Smith',
        pic_url: '',
        status: 'active',
      },
      mock: true,
    };
  }, [mock]);
}

export function useZoomGuestFeatureChecker(uid: string, enabled: boolean) {
  const { data: packs } = useSWRImmutable(
    enabled ? ['/game-packs', 'zoomFree'] : null,
    async () => {
      const resp = await apiService.gamePack.getGamePacksByCollection(
        'zoomFree'
      );
      return resp.data.gamePacks;
    }
  );
  return useMemo(() => {
    if (!enabled) return;
    return new FeatureChecker(uid, [], {
      gamePackIds: packs?.map((p) => p.id) ?? [],
    });
  }, [enabled, packs, uid]);
}

export function useZoomClient(mock?: boolean): IZoomClient {
  const client = useMemo(() => {
    if (mock) {
      return new MockZoomClient({
        meetingId: getFeatureQueryParamString('zoom-mock-meeting-id'),
        runningContext: getFeatureQueryParamArray('zoom-mock-running-context'),
      });
    }
    return new ZoomClient();
  }, [mock]);
  return client;
}
